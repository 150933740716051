import colors from "../../tokens/colors";

const iconURLs: Record<string, { name: string; color: string }> = {
  "Bottom Hole Assembly": { name: "BHA", color: colors.lightGreen[600] },
  Cementing: { name: "Cm", color: colors.pink[500] },
  "Drilling Performance": { name: "DP", color: colors.aqua[500] },
  "Duty Handover": { name: "DH", color: colors.aqua[500] },
  "Executive Outlook": { name: "EO", color: colors.lime[500] },
  Geomechanics: { name: "Gm", color: colors.lightGreen[300] },
  "Mud Tracking": { name: "MT", color: colors.orange[500] },
  "Mud Tracking Upload": { name: "MTU", color: colors.orange[500] },
  "Operations Outlook": { name: "OO", color: colors.sand[500] },
  "Operations Outlook (Classic)": { name: "OO", color: colors.sand[500] },
  "POA Outlook": { name: "PO", color: colors.lime[400] },
  "POA Outlook (Classic)": { name: "PO", color: colors.lime[400] },
  "Rig Schedule": { name: "RS", color: colors.pink[300] },
  "Apps Usage": { name: "AU", color: colors.lightGreen[500] },
  "Upload iQx Data": { name: "UI", color: colors.lightGreen[400] },
  "Upload AFE Data": { name: "UA", color: colors.lightGreen[300] },
  Survey: { name: "Sv", color: colors.purple[400] },
  "Survey Benchmarking": { name: "SB", color: colors.purple[400] },
  Formations: { name: "Fm", color: colors.aqua[400] },
  "Source Mapper": { name: "SM", color: colors.orange[400] },
};

export default function appIconInfo(name: string) {
  if (!(name in iconURLs)) throw new Error(`Invalid app name: ${name}`);
  return iconURLs[name];
}
