import { type DeployConfigFrontend } from "library-frontend-utils/infra";

const config: DeployConfigFrontend = {
  local: {
    authenticationApp: "digitalSolutionsPlatformDev",
  },
  dev: {
    authenticationApp: "digitalSolutionsPlatformDev",
  },
  prod: {
    authenticationApp: "digitalSolutionsPlatformProd",
  },
  t60dev: {
    authenticationApp: "digitalSolutionsPlatformT60Dev",
  },
  t60prod: {
    authenticationApp: "digitalSolutionsPlatformT60Prod",
  },
  varenergidev: {
    authenticationApp: "odpDev",
  },
  varenergitest: {
    authenticationApp: "odpDev",
  },
  varenergiprod: {
    authenticationApp: "odpProd",
  },
};

export default config;
