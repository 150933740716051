import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Link, Typography } from "@mui/joy";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useFeedbackForm } from "library-frontend-utils/sentry/helpers/useFeedbackForm";

interface SectionErrorStateProps {
  children: React.ReactNode;
  type: "error" | "empty";
  translations?: TranslationsType<typeof translationStrings>;
  title?: string;
  description?: string;
}

const translationStrings = [
  "Error fetching data",
  "Please refresh the page or try again later.",
  "No data to show yet!",
  "A data source will be available soon.",
  "The source to which we’re trying to connect isn’t responding. Please try refreshing the page.",
  "If the issue persists, ",
  "send a feedback",
  "or",
  "ask for help.",
] as const;

const helpLink =
  "https://teams.microsoft.com/l/channel/19%3A81e4f72b30854bb5a84979b860553397%40thread.tacv2/Feedback?groupId=88b767b1-6143-4957-beb2-b0ee0efcd41e&tenantId=77da4c42-ba77-462b-bb54-7f7ea57bd0a8&ngc=true";

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 1, staleTime: 12 * 60 * 60 * 1000 } },
});

export function SectionErrorState({
  children,
  type,
  translations,
  description,
  title,
}: SectionErrorStateProps) {
  const t = { ...defaultTranslations(translationStrings), ...translations };
  const { openFeedbackForm } = useFeedbackForm();

  const getCustomDescription = () => {
    return type === "empty" ? (
      t["A data source will be available soon."]
    ) : (
      <>
        {
          t[
            "The source to which we’re trying to connect isn’t responding. Please try refreshing the page."
          ]
        }
        <br />
        <br />
        {t["If the issue persists, "]}
        <Link
          color="neutral"
          textColor="text.secondary"
          underline="always"
          onClick={openFeedbackForm}
          text-decoration-color="none"
        >
          {t["send a feedback"]}
        </Link>{" "}
        {t["or"]}{" "}
        <Link
          color="neutral"
          textColor="text.secondary"
          underline="always"
          href={helpLink}
          text-decoration-color="text.secondary"
        >
          {t["ask for help."]}
        </Link>
      </>
    );
  };

  const getCustomTitle = () => {
    return type === "empty"
      ? t["No data to show yet!"]
      : t["Error fetching data"];
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="sm"
        overflow="hidden"
        flexWrap="nowrap"
      >
        <Box
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          height="100%"
          zIndex={1}
          sx={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, var(--joy-palette-background-body) 100%)",
          }}
        />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          zIndex={1}
          textAlign="center"
          maxWidth="340px"
          sx={{ transform: "translate(-50%, -50%)" }}
        >
          <FontAwesomeIcon
            icon={type === "empty" ? faEyeSlash : faCircleExclamation}
            style={{
              fontSize: "30px",
              color:
                type === "empty"
                  ? "var(--joy-palette-text-icon)"
                  : "var(--joy-palette-warning-500)",
            }}
          />
          <Typography level="h4" sx={{ marginBottom: 2 }}>
            {title ?? getCustomTitle()}
          </Typography>
          <Typography
            level="title-sm"
            textColor="text.secondary"
            textAlign="center"
          >
            {description ?? getCustomDescription()}
          </Typography>
        </Box>
        <Box zIndex={0} position="relative" width="100%">
          {children}
        </Box>
      </Box>
    </QueryClientProvider>
  );
}
