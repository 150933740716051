import { type Static, Type } from "@sinclair/typebox";

export const PulumiStackConfigSchema = Type.Object(
  {
    config: Type.Object(
      {
        "azure-native:subscriptionId": Type.String({
          description: "Azure Subscription ID",
        }),
        "pulumi:tags": Type.Object(
          {
            customer: Type.Union([
              Type.Literal("three60", {
                description: "Customer for the current stack",
              }),
              Type.Literal("varenergi", {
                description: "Customer for the current stack",
              }),
              Type.Literal("t60", {
                description: "Customer for the current stack",
              }),
            ]),
            environment: Type.Union([
              Type.Literal("production", {
                description: "Environment type for the current stack",
              }),
              Type.Literal("test", {
                description: "Environment type for the current stack",
              }),
              Type.Literal("development", {
                description: "Environment type for the current stack",
              }),
            ]),
          },
          {
            title: "Stack Tags",
            description: "Stack metadata",
            additionalProperties: false,
          }
        ),
        "azure-native:tenantId": Type.String({
          title: "Azure Tenant ID",
          description: "Tenant ID for Azure AD",
          format: "uuid",
        }),
        "typescript:container-registry-password": Type.Optional(
          Type.Object(
            {
              secure: Type.String({
                title: "Encrypted Password for Container Registry",
              }),
            },
            {
              description: "Secure password for container registry",
              additionalProperties: false,
            }
          )
        ),
      },
      {
        description: "A list of configurations",
        additionalProperties: false,
      }
    ),
  },
  {
    title: "Pulumi Stack Config",
    description: "Schema for Pulumi Stack Configuration in typescript project",
    additionalProperties: false,
  }
);

export type PulumiStackConfigFile = Static<typeof PulumiStackConfigSchema>;
